import { ComputedRef, Ref, computed, reactive } from "vue";
import { useAuthStore } from "@/store/authStore"; // Update with the correct path
import { FormRef } from "@/models/UI";
import { IUser } from "@/models/trading";
import {
  Deal,
  FilterCondition,
  MarketDisplayItemContract,
  UnMatchedDeal,
} from "@/models/marketData";
import useConsoleLogger from "./useConsoleLogger";
// import { useSetup } from "/path/to/useSetup"; // Update with the correct path

const CType = ["futures", "options", "splits", "spreads", "deltas"] as const;
export type ContractType = (typeof CType)[number];
interface Filters extends Record<ContractType, FilterCondition[]> {
  futures: FilterCondition[];
  options: FilterCondition[];
  splits: FilterCondition[];
  spreads: FilterCondition[];
  deltas: FilterCondition[];
}
export default function useContractTypeFilters() {
  const filters = reactive<Filters>({
    futures: [
      { field: "contractDisplay.flag", value: "F", operator: "==" },
      { field: "contractDisplay.strike", value: 0, operator: "==" },
      { field: "contractDisplay.CONTRACT_TYPE", value: 1, operator: "==" },
    ],
    options: [
      { field: "contractDisplay.flag", value: "F", operator: "!==" },
      { field: "contractDisplay.CONTRACT_TYPE", value: 2, operator: "==" },
      { field: "contractDisplay.strike", value: 0, operator: "!==" },
    ],
    splits: [
      { field: "contractDisplay.CONTRACT_TYPE", value: 4, operator: "==" },
    ],
    spreads: [
      { field: "contractDisplay.CONTRACT_TYPE", value: 3, operator: "==" },
    ],
    deltas: [
      { field: "contractDisplay.flag", value: "F", operator: "!==" },
      { field: "contractDisplay.strike", value: "0", operator: "!==" },
      { field: "contractDisplay.CONTRACT_TYPE", value: 5, operator: "==" },
    ],
  });
  function applyConditions(item: any, conditions: FilterCondition[]): boolean {
    
    return conditions.every((condition) => {
      const fields = condition.field.split("/");
      const { operator, value } = condition;

      for (let field of fields) {
        let fieldValue = field.split(".").reduce((o, i) => o?.[i], item);
        // debugger;
        switch (operator) {
          // case "==":
          //   if (fieldValue == value) return true;
          //   break;

          // case "!==":
          //   if (fieldValue !== value) return true;
          //   break;

          // case ">":
          //   if (fieldValue > value) return true;
          //   break;

          // case "<":
          //   if (fieldValue < value) return true;
          //   break;

          // case ">=":
          //   if (fieldValue >= value) return true;
          //   break;

          // case "<=":
          //   if (fieldValue <= value) return true;
          //   break;

          case "==":
            return fieldValue == value;

          case "!==":
            return fieldValue !== value;
          case ">":
            return fieldValue > value;

          case "<":
            return fieldValue < value;

          case ">=":
            return fieldValue >= value;

          case "<=":
            return fieldValue <= value;

          case "contains":
            const val = value || "";
            if (
              typeof fieldValue === "string" &&
              fieldValue.toLowerCase().includes(val.toLowerCase())
            ) {
              return true;
            }
            break;

          default:
            useConsoleLogger.log(
              "Op full default: ",
              fieldValue,
              operator,
              value
            );
            return false;
        }
      }

      // If none of the fields meet the condition, return false.
      return false;
    });
  }
  // function applyConditions(item: any, conditions: FilterCondition[]): boolean {
  //   return conditions.every((condition) => {
  //     const fieldValue = condition.field
  //       .split(".")
  //       .reduce((o, i) => o[i], item);
  //     const { operator, value } = condition;

  //     switch (operator) {
  //       case "==":
  //         return fieldValue == value;

  //       case "!==":
  //         return fieldValue !== value;
  //       case ">":
  //         return fieldValue > value;

  //       case "<":
  //         return fieldValue < value;

  //       case ">=":
  //         return fieldValue >= value;

  //       case "<=":
  //         return fieldValue <= value;
  //       case "contains":
  //         if (value == "") {
  //           return true
  //         }
  //         debugger;
  //         const arrs = condition.field.split("/");
  //         for (let split of arrs) {
  //           if (item[split].toLowerCase().includes(value.toLowerCase())) {
  //             return true;
  //           }
  //         }
  //         return false;
  //         // return fieldValue.toLowerCase().includes(value.toLowerCase());
  //       default:
  //         useConsoleLogger.log(
  //           "Op full default: ",
  //           fieldValue,
  //           operator,
  //           value
  //         );
  //         return false;
  //     }
  //   });
  // }
  function determineContractType(
    item?: MarketDisplayItemContract | UnMatchedDeal | Deal
  ): ContractType | undefined {
    for (const filterKey in filters) {
      const filterConditions = filters[filterKey as ContractType];
      if (applyConditions(item, filterConditions)) {
        return filterKey as ContractType;
      }
    }
    // Return 'undefined' or some default value if none of the filters match
    return undefined;
  }

  return {
    filters,
    determineContractType,
    applyConditions,
  };
}
